import './recFrame.scss';
import recFrame from "../../assets/recFrame.png"
import { UIContext } from '../../contexts/UIContext';
import { useContext } from 'react'

const RecFrame = ({ handleWatch }) => {
    const { showControls, isScrolling } = useContext(UIContext)
    return (
        <div id="rec-frame" className='recFrame'>
            <div className='rec_top_left'><img src={recFrame} /></div>
            <div className='rec_top_right'><img src={recFrame} /></div>
            <div className='rec_bottom_left'><img src={recFrame} /></div>
            <div className='rec_bottom_right'><img src={recFrame} /></div>
            {showControls.current &&
                <div className='controls'>
                    <button onClick={handleWatch}>Watch</button>
                </div>
            }
        </div>
    );
}

export default RecFrame