import './App.scss';
import { Canvas } from '@react-three/fiber'
import { Suspense, useEffect, useRef, useState } from 'react'

import BackgroundScene from './components/3Dbackground/3Dbackground'
import Videofeed from './components/videofeed/videoFeed'
import VideoComponent from './components/videoComponent/videoComponent'
import RecFrame from './components/recFrame/recFrame';
import { UIContext } from './contexts/UIContext';
import Loading from './components/loading/loading'

function App() {
  // Used to force re-render
  const [empty, setEmpty] = useState(0)
  const [currentVideo, setCurrentVideo] = useState(-1)
  const candidateVideo = useRef(null)
  const [UIContextValue, setUIContextValue] = useState({
    isScrolling: { current: false },
    scrollingDir: { current: 1 },
    showControls: { current: false },
    currentVideo: { current: -1 },
    udpateIsScrolling: () => { },
    udpateScrollingDir: () => { }
  })
  useEffect(() => {
    const udpateIsScrolling = (boolValue) => {
      UIContextValue.isScrolling.current = boolValue
      setCurrentVideo(prev => {
        if (prev !== null && boolValue) return -1; else return prev
      })
    }
    const udpateScrollingDir = (boolValue) => {
      // 0 is up, 1 is down
      UIContextValue.scrollingDir.current = boolValue
    }
    setUIContextValue(() => ({
      ...UIContextValue,
      udpateIsScrolling,
      udpateScrollingDir
    }))
  }, [])

  const toggleControls = (boolValue, screenIndex) => {
    if (boolValue != UIContextValue.showControls.current || screenIndex != candidateVideo.current) {
      UIContextValue.showControls.current = boolValue
      candidateVideo.current = screenIndex
      setEmpty(prev => prev + 1)
    }
  }
  const handleWatch = () => {
    setCurrentVideo(candidateVideo.current)
  }

  return (
    <UIContext.Provider value={UIContextValue}>
      <div id="canvas-container" className='App'>
        {currentVideo !== -1 &&
          <VideoComponent {...{ currentVideo }} />
        }
        <Videofeed />
        <RecFrame {...{ handleWatch }} />
        <Canvas
          style={{ width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0, zIndex: -1 }}
        >
          <Suspense fallback={<Loading />}>

            <BackgroundScene {...{ currentVideo, toggleControls }} />
          </Suspense>
        </Canvas>
      </div>
    </UIContext.Provider>
  );
}



export default App;
