import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { UIContext } from '../contexts/UIContext';

const useIsScrolling = () => {
    const { isScrolling, udpateIsScrolling, udpateScrollingDir } = useContext(UIContext);
    const scrollCheckerRef = useRef(null)
    const scrollYRef = useRef(window.scrollY)

    const programCheckScrollState = useCallback(() => {
        scrollYRef.current = window.scrollY;
        clearTimeout(scrollCheckerRef.current)
        scrollCheckerRef.current =
            setTimeout(() => {
                if (window.scrollY == scrollYRef.current) {
                    udpateIsScrolling(false)
                } else {
                    programCheckScrollState()
                }
            }, 100)
    }, [udpateIsScrolling, udpateScrollingDir])


    const handleScroll = useCallback(() => {
        udpateScrollingDir(window.scrollY > scrollYRef.current ? 1 : 0);
        if (Math.abs(window.scrollY - scrollYRef.current) > 10) {
            if (!isScrolling.current) {
                udpateIsScrolling(true);
                programCheckScrollState();
            }
        }
    }, [udpateIsScrolling, udpateScrollingDir])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    return;
}

export default useIsScrolling