import './3Dbackground.scss';
import { useLoader, useFrame } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

import { useThree } from '@react-three/fiber'
import * as THREE from "three";
import { useEffect, useRef, useState, useCallback, useContext } from 'react'
import { degToRad } from 'three/src/math/MathUtils';
import useInterval from '../../hooks/useInterval';
import { UIContext } from '../../contexts/UIContext';
import useIsScrolling from '../../hooks/useIsScrolling'

const upvector = new THREE.Vector3(0, 1, 0)


const BackgroundScene = ({ toggleControls, currentVideo }) => {
    const { showControls, isScrolling, scrollingDir } = useContext(UIContext)
    useIsScrolling()
    const wholeScene = useLoader(GLTFLoader, '/3DScene.glb', () => {
    })
    const bgHDRI = useLoader(RGBELoader, '/Background.hdr')
    bgHDRI.mapping = THREE.EquirectangularReflectionMapping
    useThree(({ scene }) => {
        scene.background = bgHDRI;
        scene.environment = bgHDRI
    }
    );
    const [mixer, setMixer] = useState(new THREE.AnimationMixer(wholeScene.scene))
    const [character] = useState(wholeScene.scene.children.find(child => child.name === "Armature"))
    const [screen1] = useState(wholeScene.scene.children.find(child => child.name === "screen1"))
    const [screen2] = useState(wholeScene.scene.children.find(child => child.name === "screen2"))
    const [screen3] = useState(wholeScene.scene.children.find(child => child.name === "screen3"))
    const [screen4] = useState(wholeScene.scene.children.find(child => child.name === "screen4"))

    const isCharacterBeforeScreen = (screen) => {
        let relevantDistance = 4;
        return Math.abs(screen.position.z - character.position.z) < relevantDistance
    }
    const idleTimeoutRef = useRef(null)
    const runningTimeoutRef = useRef(null)
    const lookAtRef = useRef(character.position)

    const _camera = useThree(({ camera }) => camera);
    const [camera] = useState(_camera)

    const clips = wholeScene.animations;
    const clipIdle = THREE.AnimationClip.findByName(clips, 'Idle');
    const clipRunning = THREE.AnimationClip.findByName(clips, 'Running');
    const actionIdle = mixer.clipAction(clipIdle);
    const actionRunning = mixer.clipAction(clipRunning);

    useEffect(() => {
        actionIdle.play();
    }, [])

    const checkActiveScreen = useCallback(() => {
        if (isCharacterBeforeScreen(screen1)) {
            toggleControls(true, 1)
        }
        else if (isCharacterBeforeScreen(screen2)) {
            toggleControls(true, 2)
        } else if (isCharacterBeforeScreen(screen3)) {

            toggleControls(true, 3)
        } else if (isCharacterBeforeScreen(screen4)) {

            toggleControls(true, 4)
        }
        else if (showControls.current) {
            toggleControls(false)
        }
    }, [showControls.current])

    useInterval(checkActiveScreen, 100)


    function updateAnimation() {
        mixer.update(0.01);
    }

    const getScreenPosForVideo = videoIndex => {
        switch (videoIndex) {
            case 1:
                return screen1.position
            case 2:
                return screen2.position
            case 3:
                return screen3.position
            case 4:
                return screen4.position
            default:
                return screen1.position
        }
    }
    useFrame(() => {
        updateAnimation();
        let scrollPercentage = window.scrollY / (document.body.clientHeight - window.innerHeight);
        let rotAngle = scrollingDir.current === 1 ? 0 : degToRad(180);
        character.setRotationFromAxisAngle(upvector, rotAngle)
        character.position.set(0, 0, Math.pow(100, scrollPercentage) - 2);
        let charPos = new THREE.Vector3();
        charPos.copy(character.position);
        let camLookAt = new THREE.Vector3();
        camLookAt.copy(character.position);

        if (scrollPercentage > 0.95) {
            let restingPosOffset = new THREE.Vector3(0, 2, -1)
            let lookAtOffset = new THREE.Vector3(0, 2, 0);
            camera.position.lerp(charPos.add(restingPosOffset), 0.01);
            lookAtRef.current = camLookAt.add(lookAtOffset)
            camera.lookAt(charPos.lerp(lookAtRef.current, 0.1))
            if (isScrolling.current) {
                actionIdle.stop();
                actionRunning.play();
            } else {
                actionIdle.play();
                actionRunning.stop();
            }
            return;
        }
        if (currentVideo !== -1) {
            let screenLookAtOffset = new THREE.Vector3(2.5, 0, 0)
            let nextCamPos = new THREE.Vector3(0, 0, 0)
            let targetScreenPos = getScreenPosForVideo(currentVideo)
            nextCamPos.copy(targetScreenPos).add(screenLookAtOffset)

            camera.position.lerp(nextCamPos, 0.2)
            lookAtRef.current.lerp(targetScreenPos, 0.5)
            camera.lookAt(lookAtRef.current)
            return
        }


        let posOffsetWhenRunning = new THREE.Vector3(0, 0.2, -5)
        let lookAtOffsetWhenRunning = new THREE.Vector3(0, 0.2, 0)
        if (isScrolling.current) {
            let posOffset = posOffsetWhenRunning
            let lookAtOffset = lookAtOffsetWhenRunning
            camera.position.lerp(charPos.add(posOffset), 0.2)
            lookAtRef.current = camLookAt.add(lookAtOffset)
            camera.lookAt(lookAtRef.current)
            actionIdle.stop();
            actionRunning.play();
        } else {
            actionIdle.play();
            actionRunning.stop();
            if (!showControls.current) {
                let restingPosOffset = new THREE.Vector3(15, 5, -15)
                let lookAtOffset = new THREE.Vector3(0, 0, 0);
                camera.position.lerp(charPos.add(restingPosOffset), 0.01);
                lookAtRef.current = camLookAt.add(lookAtOffset)
                camera.lookAt(lookAtRef.current)
            } else {
                let lookAtOffset = new THREE.Vector3(0, 2, 0);
                let newLookAt = new THREE.Vector3().copy(charPos).add(lookAtOffset);
                let restingPosOffset = new THREE.Vector3(5, 1, 0)
                camera.position.lerp(charPos.add(restingPosOffset), 0.01);
                camera.lookAt(lookAtRef.current.lerp(newLookAt, 0.01))
            }
        }
    })

    return (<primitive object={wholeScene.scene} />)

}

export default BackgroundScene