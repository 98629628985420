import { createContext } from 'react'

export const UIContext = createContext()

export const defaultUI = {
    showControls: null,
    isScrolling: null,
    scrollingDir: null,
    updateIsScrolling: () => { },
    updateScrollingDir: () => { }
}
