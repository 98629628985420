import './loading.scss';
import { Html } from '@react-three/drei'
import pencil from "../../assets/pencil.png"

const Loading = () => {

    return <Html>
        <div className="loadingPage">
            <img src={pencil} width={100} height={100} />
            <div className='text'>Wait a sec, drawing trees ...</div>
        </div>
    </Html>;
}

export default Loading