
import BandeDemo from "../../assets/videos/BandeDemo.mp4"
import GameTrailer from "../../assets/videos/gameTrailer.mp4"
import Waffles from "../../assets/videos/waffles.mp4"
import Robbers from "../../assets/videos/robbers.mp4"
import './videoComponent.scss';


const getFile = (currentVideo) => {
    switch (currentVideo) {
        case 1:
            return BandeDemo;
        case 2:
            return GameTrailer;
        case 3:
            return Waffles;
        case 4:
            return Robbers;
        default:
            break;
    }
}

const VideoComponent = ({ currentVideo }) => {
    return (
        <div id="video-container">
            <video controls width="750" height="500" autoPlay>
                <source src={getFile(currentVideo)} type="video/mp4" />
                Your browser does not support the video element. Kindly update it to latest version.
            </video >
        </div>
    );
}

export default VideoComponent;